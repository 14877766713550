import React from "react"

import SEO from "@/components/seo"
import Sizing from "@/components/general/Sizing"
import styles from "@/styles/privacy.module.scss"

import { Link } from "gatsby"

const PrivacyPolicy = () => (
    <>
        <SEO
            title="Free Markets Destroy | Privacy Policy"
            description="Washington Policy Center's Privacy Policy applies when you visit or use our websites, apps and other services, including events, that refer or link to this privacy policy. Find out more about how we collect and manage your data."
        />

        <div className={styles.pad}></div>
        <div className={styles.main}>
            <span className="copy h2">
                {" "}
                Washington Policy Center Privacy Policy
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                About this Privacy Policy
            </span>
            <span className="copy">
                This privacy policy applies when you visit or use our websites,
                apps and other services, including events, that refer or link to
                this privacy policy (each, a “Service”). This privacy policy may
                be supplemented by additional privacy statements, terms or
                notices provided on certain areas of the Service or during our
                interactions with you.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                Information We Collect
            </span>
            <span className="copy">
                We collect information about you in three ways: directly from
                your input, through the Service’s technologies, and from third
                party sources. This can include collecting your data from
                registration forms, badge/business card scanners, email sign ups
                and other event related apps.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                Data You Provide to Us
            </span>
            <ul>
                <li>
                    The types of personal information that we collect directly
                    from you depends on how you interact with us and the Service
                    and includes:
                </li>
                <li>
                    Contact details, such as your name, email address, social
                    media handle, mailing address and phone number
                </li>
                <li>
                    Other account or event registration and profile information,
                    such as educational and professional background and photo,
                    and for events dietary and accessibility requirements
                </li>
                <li>
                    Payment information, such as a credit or debit card number
                </li>
                <li>
                    Comments, feedback and other information you provide to us,
                    including search query data and questions or information you
                    send to us
                </li>
            </ul>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Data from Service, Apps and Website Use
            </span>
            <span className="copy">
                The Service automatically collects information about how you and
                your device interact with the Service, including:
            </span>
            <ul>
                <li>
                    Computer, device and connection information, such as IP
                    address, browser type and version, operating system and
                    other software installed on your device, mobile platform and
                    unique device identifier and other technical identifiers,
                    error reports and performance data
                </li>
                <li>
                    Usage data, such as the features you used, the settings you
                    selected, URL click stream data, including date and time
                    stamp, and referring and exit pages, and pages you visited
                    or searched for on the Service
                </li>
                <li>
                    For location-aware Services, the region, city or town where
                    your device is located in order to provide you with more
                    relevant content for where you are in the world.
                </li>
            </ul>
            <span className="copy">
                We collect this data through our servers and the use of cookies
                and other technologies. Information obtained from cookies and
                linked to personally identifying information is treated as
                personal information. You can control cookies through your
                browser’s settings and other tools. However, if you block
                certain cookies, you may not be able to register, login, or
                access certain parts or make full use of the Service.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                Data from Other Sources
            </span>
            <span className="copy">
                We also may obtain personal information about you from other
                third parties, including:
            </span>
            <ul>
                <li>
                    Social networks when you grant permission to access your
                    data on one or more networks
                </li>
                <li>
                    Our service providers that help us determine a location
                    based on your IP address in order to customize the Service
                    to your location
                </li>
                <li>
                    Partners with which we offer co-branded services or engage
                    in joint marketing activities or event sponsorship and
                    hosting
                </li>
                <li>
                    Publicly available sources and data suppliers from which we
                    obtain data to validate or supplement the information we
                    hold.
                </li>
            </ul>
            <span className={"copy copy--lead " + styles.heading}>
                How We Use Your Information
            </span>
            <span className="copy">
                Depending on how you interact with us and the Service and your
                preferences, we use your personal information to:
            </span>
            <ul>
                <li>
                    Provide, activate and manage your access to and use of the
                    Service
                </li>
                <li>
                    Process and fulfil a request, order, download, subscription,
                    or other transaction (including to process payments)
                </li>
                <li>
                    Provide technical, product and other support and to help
                    keep the Service working, safe and secure
                </li>
                <li>
                    Enhance and improve the Service and our other products and
                    services and to develop new products, services and benefits
                </li>
                <li>
                    Offer you customized content and other personalization to
                    make the Service more relevant to your interests and
                    geography
                </li>
                <li>
                    Respond to your requests, inquiries, comments, and concerns
                </li>
                <li>
                    Notify you about changes, updates or other announcements
                    related to the Service and our other products and services
                </li>
                <li>
                    Deliver targeted advertisements, promotional messages,
                    notices, and other information related to the Service, your
                    interests and other products, events and services
                </li>
                <li>
                    For events: to include your professional details on delegate
                    booklets, badges, passes, place cards, guest lists, seating
                    charts, networking portals, and where applicable for speaker
                    and panel presenter listings
                </li>
                <li>
                    Invite you to participate in user testing and surveys as
                    well as sweepstakes, competitions and similar promotions
                </li>
                <li>
                    Identify usage trends and develop data analysis, including
                    for purposes of research, audit, reporting and other
                    business operations, including determining the effectiveness
                    of our promotional campaigns and evaluating our business
                    performance, or in other ways pursuant to a customer
                    agreement
                </li>
                <li>
                    Comply with our legal obligations, resolve disputes, and
                    enforce our agreements.
                </li>
            </ul>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Sharing of your Information
            </span>
            <span className="copy">
                It is our policy not to share the personal information we
                collect from you through our Services with third parties, except
                as described in this Policy or as otherwise disclosed.{" "}
            </span>
            <span className="copy">
                For example, we may share personal information as follows:{" "}
            </span>
            <ul>
                <li>
                    with vendors, consultants, and other service providers when
                    you give us your consent to do so, including if we notify
                    you, that the information you provide will be shared in a
                    particular manner and you provide such information
                </li>
                <li>
                    when we believe in good faith that we are lawfully
                    authorized or required to do so or that doing so is
                    reasonably necessary or appropriate to comply with the law
                    or legal processes or respond to lawful requests, claims or
                    legal authorities, including responding to lawful subpoenas,
                    warrants, or court orders
                </li>
                <li>
                    when we believe in good faith that doing so is reasonably
                    necessary or appropriate to respond to claims or to protect
                    the rights, property, or safety of Washington Policy Center,
                    our users, our employees, our volunteers, copyright owners,
                    third parties or the public, including without limitation to
                    protect such parties from fraudulent, abusive,
                    inappropriate, or unlawful activity or use of our Site
                </li>
                <li>
                    to enforce or apply this Policy, our Terms of Service, or
                    our other policies or agreements; and in connection with, or
                    during negotiations of, any merger, reorganization,
                    acquisition, asset sale, financing or lending transaction or
                    in any other situation where personal information may be
                    disclosed or transferred as one of the assets of Washington
                    Policy Center.{" "}
                </li>
            </ul>
            <span className="copy">
                We are not responsible for the actions of any service providers
                or other third parties, nor are we responsible for any
                additional information you provide directly to any third
                parties, and we encourage you to become familiar with their
                privacy practices before disclosing information directly to any
                such parties. Nothing herein restricts the sharing of aggregated
                or anonymized information, which may be shared with third
                parties without your consent.
            </span>
            <span className="copy">
                Online petitions: When you sign an online petition, you
                understand that such petition is public information and that we
                may make the petition, and your name, city, state, and any
                comments provided in connection therewith publicly available. In
                addition, we may provide such petitions or compilations thereof,
                including your comments, name, city, and state to state or local
                leaders, or to the press.
            </span>
            <span className="copy">
                Links to Other Websites: Our Sites may contain links to other
                websites. Any personal information you provide on the linked
                pages is provided directly to that third party and is subject to
                that third party’s privacy policy. This Policy does not apply to
                such linked sites, and we are not responsible for the content or
                privacy and security practices and policies of these websites or
                any other sites that are linked to from our Sites. We encourage
                you to learn about their privacy and security practices and
                policies before providing them with personal information.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                Legal Reasons
            </span>
            <span className="copy">
                We also disclose your personal information if we have a good
                faith belief that such disclosure is necessary to:
            </span>
            <ul>
                <li>
                    meet any applicable law, regulation, legal process or other
                    legal obligation
                </li>
                <li>
                    detect, investigate and help prevent security, fraud or
                    technical issues
                </li>
                <li>
                    protect the rights, property or safety of WASHINGTON POLICY
                    CENTER, our users, employees and others
                </li>
            </ul>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Your Communications Preferences
            </span>
            <span className="copy">
                {" "}
                You can customize and manage your communications preferences and
                your contact details by emailing WPC@washingtonpolicy.org. We
                reserve the right to notify you of changes or updates to the
                Service whenever necessary.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Accessing and Updating your Information
            </span>
            <span className="copy">
                {" "}
                The Service allows users to review their account information by
                an email request to WPC@washingtonpolicy.org to make corrections
                or updates. Keeping such information up to date is solely the
                responsibility of the user.{" "}
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Data Retention
            </span>
            <span className="copy">
                {" "}
                We retain your personal information for as long as necessary to
                provide the Service and fulfill the transactions you have
                requested, and for other essential purposes such as complying
                with our legal obligations, maintaining business and financial
                records, resolving disputes, maintaining security, detecting and
                preventing fraud and abuse, and enforcing our agreements.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Children’s Privacy
            </span>
            <span className="copy">
                {" "}
                We do not knowingly collect information from children under the
                age of 13 or target the Service to children under 13.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Data Security
            </span>
            <span className="copy">
                {" "}
                We use a variety of administrative, physical and technical
                security measures to help safeguard your personal information.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Locations of Processing
            </span>
            <span className="copy">
                {" "}
                Your personal information may be stored and processed in your
                region or another country where the Washington Policy Center and
                our service providers maintain servers and facilities, including
                Australia, Brazil, France, Germany, Italy, Ireland, the
                Netherlands, Singapore, South Africa, the United Kingdom, and
                the United States. We take steps, including through our
                contracts, to ensure that the information continues to be
                protected wherever it is located in a manner consistent with the
                standards of protection required under applicable law.
            </span>
            <span className="copy">
                {" "}
                Where personal information is transferred from the European
                Economic Area (“EEA”) or Switzerland to a country that has not
                received an adequacy decision by the European Commission, we
                rely on appropriate safeguards, such as the European
                Commission-approved Standard Contractual Clauses and the EU-U.S.
                and Swiss-U.S. Privacy Shield Frameworks, to transfer the data.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Grounds for Processing
            </span>
            <span className="copy">
                {" "}
                When we collect from you any personal information within the
                scope of European data protection laws, we do so (a) with your
                consent; (b) where necessary to provide the Service, fulfill a
                transaction or otherwise perform a contract with you or at your
                request prior to entering into a contract; (c) where necessary
                for our compliance with a legal obligation; (d) where necessary
                for the performance of a task carried out in the public
                interest; and/or (e) as necessary to fulfill our legitimate
                interests as described below, except where our interests are
                overridden by your privacy rights. Where we rely on your consent
                to process personal information, you have the right to withdraw
                your consent at any time, and where we rely on legitimate
                interests, you have the right to object.
            </span>
            <span className="copy">
                {" "}
                Our legitimate interests are: to deliver our products to our
                customers and their end users; to detect or prevent fraud; to
                protect the security of our systems, customers and users; to
                further develop our products; to conduct direct marketing; to
                operate our business (by processing payments/invoices and
                conduct credit checks on customers, suppliers and leads, perform
                sales and customer relations management, supplier management, as
                well as incidental processing in our back office for these
                purposes); to enable our customers to comply with legal
                obligations; to provide customer service or support; or fulfil
                our other legitimate interests as described above.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                {" "}
                Your rights
            </span>
            <span className="copy">
                {" "}
                You have the right under European and certain other privacy and
                data protection laws to request free of charge:
            </span>
            <ul>
                <li>
                    access to and correction or deletion of your personal
                    information
                </li>
                <li>
                    that we cease or limit our processing of your personal
                    information
                </li>
                <li>
                    a copy of your personal information (right to data
                    portability)
                </li>
                <li>
                    to object at any time to processing of personal information
                    concerning you for direct marketing.
                </li>
            </ul>
            <span className="copy">
                If you would like to exercise any of these rights, please
                contact us at the address set out below. We will respond to your
                request consistent with applicable laws. To protect your privacy
                and security, we may require you to verify your identity.
            </span>
            <span className={"copy copy--lead " + styles.heading}>Changes</span>
            <span className="copy">
                We will update this privacy policy from time to time. Any
                changes will be posted on this page with an updated revision
                date. If we make any material changes, we will provide notice
                through the Service or by other means.
            </span>
            <span className={"copy copy--lead " + styles.heading}>
                Contact us
            </span>
            <span className="copy">
                If you have any questions, comments or requests regarding this
                privacy policy or our processing of your information, please
                contact:
            </span>
            <span className={styles.mono}>
                Operations Manager <br />
                PO Box 3643 <br />
                Seattle, WA 98124-3643{" "}
            </span>

            <a href="mailto:WPC@washingtonpolicy.org">
                {" "}
                <span className={styles.contact}>WPC@washingtonpolicy.org</span>
            </a>
            <span className={"copy copy--lead " + styles.heading}>
                How to complain
            </span>
            <span className="copy">
                We hope that we can resolve any query or concern you raise about
                our use of your information but you may also lodge a complaint
                with the data protection authority in the applicable
                jurisdiction.
            </span>
            <span className={"copy " + styles.mono}>
                <br />
                Last updated: 6/19/2020
            </span>
        </div>
    </>
)

export default PrivacyPolicy
